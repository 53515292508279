import React from 'react';
import './Home.css';
import {Button, Divider, Header, Segment} from "semantic-ui-react";

function App() {
    return (
        <Segment padded='very' textAlign='center'>
            <Header as='h3'
                    content="Authorize with Mwanana App"
            />
            <div className='.Home-message'>
                <p>You need Mwanana App to authorize this request.</p>
                <p>If you have it installed please press the button below to continue or <a
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.mwanana" target='_blank'>here</a> to
                    download
                    the app</p>
            </div>

            <Divider/>
            <Button
                type='button'
                content='Authorize'
                color='teal'
                size='large'
                onClick={() => window.open(`mwanana://oauth${document.location.search}` )}
                fluid as='a'/>
        </Segment>
    );
}

export default App;
